/**


 */
.video-container-stage {
    height: 109px;
}

.video-container-participants video,
.video-container-stage video {
    object-fit: cover;
}

.video-container-participants video {
    display: block;
    width: 164px;
    height: 94px;
}

.video-container-stage video {
    display: block;
    width: 192px;
    height: 109px;
}

.video-container-presenter .video-controls,
.video-container-stage .video-controls,
.video-container-participants .video-controls {
    position: relative;
}

.video-control-icon-container {
    position: absolute;
}

.video-container-stage .video-role-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    color: white;
    z-index: 100000;
}

.video-container-stage .video-role {
    font-size: 11px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    height: 25px;
    text-shadow: 0 1px 0 black;
    border-bottom-left-radius: 13px;
}

.video-container-presenter .video-nameplate,
.video-container-stage .video-nameplate,
.video-container-participants .video-nameplate {
    font-size: 10px;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 18px;
    margin-bottom: -18px;
    height: 18px;
    color: white;
    padding-left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
}

.video-container-presenter, .video-container-presenter > video {
    display: block;
    width: 39vw;
    height: 22vw;
}

.video-container-presenter-text {
    color: white;
    text-align: center;
    position: relative;
    top: 50px;
}

.video-container-presenter-content-share {
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-container-presenter-content-share video {
    width: 95%;
    max-height: 95%;
    max-width: 95%;
    object-fit: cover;
}

.video-controls {
    position: relative;
    z-index: 1000000000;
}

.video-control-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 1000000000;
}
