.participant-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
}

.participant-badge .participant-badge-name {
    color: white;
    white-space: nowrap;
    font-size: 10px;
    margin-top: 3px;
}

.participant-not-present {
    opacity: 0.5;
}

.participant-badge .icon-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 50%;
    background-color: white;
    color: black;
    width: 15px;
    height: 15px;
    position: relative;
    top: 10px;
    left: 15px;
    margin-top: -18px;
    z-index: 100000;
    text-align: center;
    flex-shrink: 0;
}
