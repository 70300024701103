.scroll-container {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
    display: none;
}
