
.participant-map > div,
.participant-map {
    height: 100%;
    color: black;
}

.participant-map .participant-badge span {
    color: black;
}

.participant-map .two-column {
    width: 50%;
    height: calc(100% - 10px);
    gap: 32px;
}

.participant-map .two-rows {
    height: calc(50% - 16px);
}

.participant-map .participant-badge {
    flex-direction: row;
    gap: 10px;
    white-space: nowrap;
    height: fit-content;
}

.participant-map .content-box {
    height: 100%;
}

.participant-map .room-info .scroll-container > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: flex-start;
}

.participant-map .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.participant-map .topic-cards {
    width: 100%;
    height: 100%;
}

.participant-map .breakout-card {
    width: 300px;
}

.participant-map .breakout-card .card-content {
    height: 100%;
}

.participant-map .breakout-card .scroll-container{
    max-height: 250px;
}

.participant-map .breakout-card button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D2D2D2;
    color: black;
    border-radius: 4px;
}

.participant-map .topic-cards > .scroll-container {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
}
