.group-call-roster {
    padding: 0 12px;
}

.group-call-roster .participant-video-avatar {
    width: 216px;
    height: 122px;
}

.group-call-roster .video-container-participants video {
    width: 216px;
    height: 122px;
}