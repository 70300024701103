.invitation-line {
    display: inline-block;
    width: 840px;
    height: 24px;
}

.invitation-line-email {
    width: 400px;
    display: inline-block;
}

.invitation-line-separator {
    margin-left: 16px;
    margin-right: 16px;
    display: inline-block;
}

.invitation-line-nickname {
    width: 400px;
    display: inline-block;
}

.participant-invitation-manager {
    height: 100%;
}

.participant-invitation-manager > .scroll-container {
    width: 50%;
}

.participant-invitation-manager .card .additional-button-divider {
    margin-top: 16px !important;
}

.participant-invitation-manager .participant-circle {
    background-color: #D3D3D3;
}
