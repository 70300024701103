.number-of-participants-circle > div,
.number-of-participants-circle {
    background-color: white;
    display: flex;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.number-of-participants-circle > div {
    width: 44px;
    height: 44px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}
