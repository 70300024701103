.leave-call-button {
    background-color: transparent;
    border-color: #6f6f6f;
    color: white;
    padding: 6px;
}
.leave-call-button:hover {
    background-color: transparent;
    border-color: white;
    color: white;
}
