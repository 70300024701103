.workshop-designer .phase {
    background-color: white;
    padding: 5px 10px;
    border: none !important;
    -webkit-box-shadow: 0 1px 4px #00000026;
    box-shadow: 0 1px 4px #00000026;
}

.workshop-designer .phase h3 {
    padding-top: 0;
    margin-top: 0;
    color: black;
}

.workshop-designer .phase .duration {
    font-weight: bold;
    font-size: large;
    margin: 10px 0;
}

.workshop-designer .phase .time .rc-time-picker input,
.workshop-designer .phase .time .rc-time-picker .rc-time-picker-icon {
    display: none;
}

.workshop-designer .phase .time a > span {
    background: #acacac;
    color: white;
    font-size: smaller;
    padding: 1px 5px;
    /* do not use shorthand property (result looks different) */
    border-top-left-radius: 10px 50%;
    border-top-right-radius: 10px 50%;
    border-bottom-left-radius: 10px 50%;
    border-bottom-right-radius: 10px 50%;
}

.workshop-designer a {
    cursor: pointer;
}

.workshop-designer .phase .time:first-child {
    margin-top: 10px;
}

.workshop-designer .phase .title-column a.save-icon {
    margin-top: 3px;
}

.workshop-designer .phase .title-column {
    width: 15%;
}

.workshop-designer .phase .plenary-column,
.workshop-designer .phase .topic-room-column
{
    width: 40%;
    border-left: 1px solid #E6E6E6;
}

.workshop-designer .phase .title-column {
    border-left: 1px solid #E6E6E6;
}

.workshop-designer .phase .title-column,
.workshop-designer .phase .room-config-header,
.workshop-designer .phase .room-config-container
{
    padding: 5px 10px;
}

.topic-room-column .room-config-container button{
    background-color: transparent;
}

.topic-room-column .room-config-container button:hover {
    background-color: var(--spectrum-global-color-gray-900);
}

.topic-room-column .room-config-container button:hover span {
    color: white;
}

.workshop-designer .phase .time-column {
    display: flex;
    width: 5%;
    max-width: 100px;
    justify-content: center;
}

.workshop-designer .phase .time-column .controls {
    margin-top: auto;
}

.workshop-designer .phase .room-config-header {
    border-bottom: 1px solid #E6E6E6;
    font-weight: bold;
}

.workshop-designer span {
    color: black;
}

.workshop-designer .phase .mode-of-interaction-label {
    margin-top: 6px;
    margin-right: 10px;
    color: black;
}

