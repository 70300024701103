.tab-list {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    background-color: #EBEBEB;
    box-shadow: 0 1px 4px #00000026;
    margin:0 -24px;
}

.tab-list-item {
    font-size: 15px;
    color: black;
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
}

.tab-list-item:first-child {
    padding-left: 12px;
    margin-left: 12px;
}

.tab-list-active {
    font-weight: bold;
    border: solid var(--spectrum-global-color-gray-800);
    border-width: 0 0 3px 0;
}

.tab-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 55px);
}

.tab-content .scroll-container {
    border-left:none;
}

.tabs {
    margin-top: -10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
