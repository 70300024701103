.participant-circle {
    background-color: white;
    display: flex;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000026;
    width: 42px;
    height: 42px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.participant-circle:hover {
    cursor: pointer;
}

.participant-circle > div {
    display: flex;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.topic-card .participant-circle img {
    width: 38px;
    height: 38px;
}
