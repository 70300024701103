.participant-video-avatar {
    text-align: center;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    width: 192px;
    height: 109px;
}

.video-container-presenter .participant-video-avatar {
    width: 50vw;
    height: 28vw;
}

.participant-video-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.participant-video-avatar .avatar-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.participant-video-avatar .avatar-icon-container > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8C8A91;
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.participant-video-avatar img {
    width: 80px;
    height: 80px;
}

.video-container-presenter .participant-video-avatar img {
    width: 200px;
    height: 200px;
}

.participant-video-avatar-content {
    width: 250px;
    height: 120px;
    padding-top: 25px;
    overflow: hidden;
}

.video-container-presenter.offline .participant-icon > div {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
}

.video-container-presenter.offline .participant-icon {
    width: 10vw;
    height: 10vw;
    flex-shrink: 0;
}

.video-container-presenter.offline .participant-icon img {
    width: 10vw;
    height: 10vw;
}

.video-container-presenter.offline .participant-badge .icon-symbol {
    width: 40px;
    height: 40px;
    font-size: 18px;
    left: 53px;
    top: 45px;
}

.video-container-presenter.offline .participant-badge-name {
    font-size: 18px;
}

.video-container-stage.offline > div,
.video-container-presenter.offline > div,
.video-container-participants.offline > div {
    background-color: #646464;
}

.video-container-stage.offline .avatar-icon-container,
.video-container-stage.offline .participant-video-avatar img,
.video-container-presenter.offline .avatar-icon-container,
.video-container-presenter.offline .participant-video-avatar img,
.video-container-participants.offline .avatar-icon-container,
.video-container-participants.offline .participant-video-avatar img {
    opacity: 0.5;
}




