.moderated-with-roles .moderated-with-roles-inactive-role-icon img {
    width: 50px;
    height: 50px;
}

.moderated-with-roles .moderated-with-roles-role-label {
    color: white;
    text-align: center;
    font-size: 11px;
    margin-bottom: 5px;
}

.moderated-with-roles .inactive-role-column {
    width: 35%;
    height: 100%;
    margin-right: 10px;
}

.moderated-with-roles .roster-grid-participants {
    border-top: none;
    overflow-y: visible;
    height: 100%;
}

.moderated-with-roles .active-role-roster {
    width: 330px;
    height: calc(100% - 50px);
    margin: 12px;
    margin-right: 10px;
}

.moderated-with-roles .active-role-roster .participant-video-avatar {
    width: 164px;
    height: 94px;
}

.moderated-with-roles .video-container-stage {
    height: auto;
}

.moderated-with-roles .video-container-stage .participant-video-avatar {
    width: 143px;
    height: 82px;
}

.roster-sidebar > .moderated-with-roles {
    height: 100%;
}

.roster-grid-participants > .moderated-with-roles {
    height: 100%;
}

.moderated-with-roles .active-role-column .active-role-roster,
.moderated-with-roles .inactive-role-column .inactive-role-roster {
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.moderated-with-roles .roster-grid-stage {
    border-bottom: none;
}

.moderated-with-roles .active-role-roster .local-video-active {
    width: 164px;
    height: 94px;
}

.moderated-with-roles .active-role-column ._spectrum-Rule_3c70b {
    width: 332px;
    margin-top: 5px;
}

.moderated-with-roles .inactive-role-column .inactive-role-roster {
    text-align: center;
}

.moderated-with-roles .active-role-column .active-role-roster::-webkit-scrollbar,
.moderated-with-roles .inactive-role-column .inactive-role-roster::-webkit-scrollbar {
    display: none;
}

.moderated-with-roles .inactive-role-column .inactive-role-roster {
    height: calc(100% - 100px);
}

.moderated-with-roles .inactive-role-column .networkers-role-roster-label {
    font-size: 11px;
    margin-bottom: 5px;
}
.moderated-with-roles .inactive-role-column .networkers-role-roster {
    height: 70px;
    margin-top: 15px;
}

.moderated-with-roles .roster-grid-local-video video {
    width: 250px;
    height: auto;
}
