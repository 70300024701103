.search-participants {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.search-participants > div {
    width: 100%;
}

.search-participants > div:first-child {
    margin-top: 15px;
    margin-bottom: 5px;
    width: 90%;
}

.search-participants > div:first-child svg * {
    fill: white;
}


.search-participants input::placeholder,
.search-participants input:active::placeholder,
.search-participants input::-webkit-input-placeholder,
.search-participants input::-ms-input-placeholder,
.search-participants input:-ms-input-placeholder,
.search-participants input:-moz-placeholder,
.search-participants input::-moz-placeholder,
.search-participants .is-hovered_61339 {
    color: white !important;
}

.search-participants input:focus,
.search-participants input:hover,
.search-participants input:active,
.search-participants input:placeholder-shown,
.search-participants input {
    font-size: 13px;
    width: 100%;
    color: white !important;
    background-color: #4B4B4B;
    border-color: #6F6F6F;
}

.search-participants hr {
    background-color: #6F6F6F;
}

.search-participants .search-results {
    padding-top: 10px;
}

.search-participants .search-result {
    display: flex;
    flex-direction: row;
    color: white;
    gap: 10px;
    padding: 0 10px;
}

.search-participants .search-result .description {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

.search-participants .search-result .description .participant-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 2px;
}

.search-participants .search-result .description .participant-location {
    font-size: 11px;
}

.search-participants .search-result .actions {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.search-participants .search-result .icon-button {
    padding: 12px;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    height: 24px;
    width: 32px;
    background-size: 14px;
}

.search-participants .search-result .icon-button:hover {
    background-color: #a0a0a0;
    cursor: pointer;
}

.search-participants .search-result .actions button {
    color: white;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
}
