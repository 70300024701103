
.participant-circle-with-label .participant-circle {
    z-index: 2;
}

.participant-circle-with-label .participant-circle img {
    height: 40px;
    width: 40px;
}

.participant-circle-with-label .label {
    padding: 5px 10px 5px 20px;
    margin-left: -10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #00000000;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    z-index: 1;
}
