@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.App {
  height:100%;
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: 115%;
}

.App > div {
  height:100%;
}

.time_picker_container.dark {
  position: absolute;
  width: 300px;
}

.main-content {
  display: flex;
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
  height: calc(100% - 10px);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-content * {
  transition: none;
}

.main-content #workshop-overview-title {
  color: black;
  font-size: 22px;
}

.main-content::-webkit-scrollbar {
  display: none;
}

._spectrum-Dialog_30293 > textarea, input {
  color:black !important;
}

button:hover {
  cursor: pointer;
}

.menu-left svg {
}

.global-error {
  position: fixed;
  bottom: 0;
}

[dir="ltr"] ._spectrum-Textfield-validationIcon_61339 {
  padding-right: 0;
}

.rc-time-picker-panel-combobox {
  width: 111px;
}
