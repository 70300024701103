.moderated-queue {
    height: 100%;
}

.moderated-queue .roster-grid-participants > div {
    width: 130px;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.moderated-queue .roster-grid-participants > div::-webkit-scrollbar {
    display: none;
}

.moderated-queue .roster-grid-participants,
.moderated-queue .queued-participants,
.moderated-queue .roster-grid-stage {
    justify-content: center;
    display: flex;
}

.moderated-queue .up-next-label {
    margin-top: 20px;
    margin-bottom: 5px;
    color: white;
    text-align: center;
    font-size: 11px;
}

.moderated-queue .up-next .accept-topic {
    margin-top: 20px;
    width: fit-content;
}

.moderated-queue .roster-grid-moderator-controls .hover-button-icon {
    height: 20px;
    width: 30px;
}
