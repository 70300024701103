.card-icon-topic-voting {
    margin-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    margin-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--spectrum-global-color-gray-200);
    width: 24px;
    height: 24px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-icon-topic-voting-accepted {
    color: black;
}

.card-icon-topic-voting-closed {
    color: black;
}

.participant-circle.topic-participant {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
}
.participant-circle.topic-participant > div {
    display: flex;
    height: 26px;
    width: 26px;
    justify-content: center;
    align-items: center;
}

.topic-participant img {
    height: 26px;
    width: 26px;
}

.topic-discussing-participants {
    color: var(--spectrum-global-color-gray-600);
}

.topic-discussing-participants .participant-circle {
    border: none;
    width: 26px;
    height: 26px;
}

.topic-discussing-participants .participant-circle img {
    width: 26px;
    height: 26px;
}

.topic-cards {
    margin-top: 10px;
    align-self: baseline;
}

.topic-card {
    height: fit-content;
}

.topic-card hr {
    margin-top: 8px !important;
    margin-bottom: 12px !important;
}

.topic-card .card-content .description {
    overflow: hidden;
}

.topic-card .card-content-body > div hr:nth-of-type(2) {
    margin-top: 7px !important;
    margin-bottom: 5px !important;
}

.topic-card .card-content-body > div hr:first-of-type {
    margin-top: 13px !important;
    margin-bottom: 7px !important;
}

.topic-participant-icons {
    margin-top: 5px;
}