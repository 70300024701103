.breadcrumb {
    display: flex;
    align-items: center;
}

.breadcrumb-item {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: black;
}

.breadcrumb-item:first-child{
    padding-left: 24px;
}

.breadcrumb-item:last-child {
    font-weight: bold;
}

.breadcrumb-item:not(:first-child):before{
    content:"";
    background-image: url("../../icons/svg/chevron-right.svg");
    width: 18px;
    height: 18px;
    margin: 0 4px 0 4px;
}
