.card > .card-content {
    background-color: white;
    word-wrap: anywhere;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 4px #00000026;
}

.card-content-body {
    font-size: 13px !important;
}

.card .card-title {
    color: black;
    word-wrap: anywhere;
    font-size: 18px;
    margin-bottom: 6px;
    margin-top: 12px;
}

.card hr {
    margin-bottom: 16px !important;
    margin-top: 24px !important;
}

.card .header-icons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    margin-top: -30px;
}

.card ._spectrum-ButtonGroup_25328 {
    font-size: 13px;
    font-weight: bolder;
}

.card-icon-participant img {
    height: 40px;
    width: 40px;
}

.header-icons .header-icon-right {
    position: relative;
    top: 23px;
}

.header-icons .header-icon-left {
    position: relative;
    top: 12px;
}

.card-margin {
    margin-left: var(--spectrum-global-dimension-size-150, var(--spectrum-alias-size-150));
    margin-right: var(--spectrum-global-dimension-size-150, var(--spectrum-alias-size-150));
}

.card-content-body ._spectrum-Rule_3c70b {
    margin: 0 -16px;
}

img.card-image {
    width: var(--spectrum-global-dimension-size-900, var(--spectrum-alias-size-900));
    height: var(--spectrum-global-dimension-size-900, var(--spectrum-alias-size-900));
    background-color: var(--spectrum-global-color-gray-400);
    border-radius: 4px;
}
