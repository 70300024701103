@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.App {
  height:100%;
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: 115%;
}

.App > div {
  height:100%;
}

.time_picker_container.dark {
  position: absolute;
  width: 300px;
}

.main-content {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
  height: calc(100% - 10px);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-content * {
  transition: none;
}

.main-content #workshop-overview-title {
  color: black;
  font-size: 22px;
}

.main-content::-webkit-scrollbar {
  display: none;
}

._spectrum-Dialog_30293 > textarea, input {
  color:black !important;
}

button:hover {
  cursor: pointer;
}

.menu-left svg {
}

.global-error {
  position: fixed;
  bottom: 0;
}

[dir="ltr"] ._spectrum-Textfield-validationIcon_61339 {
  padding-right: 0;
}

.rc-time-picker-panel-combobox {
  width: 111px;
}

.user-profile .two-column {
    width: 50%;
    height: 100%;
}

.participant-circle {
    background-color: white;
    display: -webkit-flex;
    display: flex;
    border-radius: 50%;
    box-shadow: 0px 1px 4px #00000026;
    width: 42px;
    height: 42px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.participant-circle:hover {
    cursor: pointer;
}

.participant-circle > div {
    display: -webkit-flex;
    display: flex;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.topic-card .participant-circle img {
    width: 38px;
    height: 38px;
}

.participant-badge {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 12px;
}

.participant-badge .participant-badge-name {
    color: white;
    white-space: nowrap;
    font-size: 10px;
    margin-top: 3px;
}

.participant-not-present {
    opacity: 0.5;
}

.participant-badge .icon-symbol {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-weight: bold;
    border-radius: 50%;
    background-color: white;
    color: black;
    width: 15px;
    height: 15px;
    position: relative;
    top: 10px;
    left: 15px;
    margin-top: -18px;
    z-index: 100000;
    text-align: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.scroll-container {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.card > .card-content {
    background-color: white;
    word-wrap: anywhere;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 4px #00000026;
}

.card-content-body {
    font-size: 13px !important;
}

.card .card-title {
    color: black;
    word-wrap: anywhere;
    font-size: 18px;
    margin-bottom: 6px;
    margin-top: 12px;
}

.card hr {
    margin-bottom: 16px !important;
    margin-top: 24px !important;
}

.card .header-icons {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    width: 90%;
    margin-top: -30px;
}

.card ._spectrum-ButtonGroup_25328 {
    font-size: 13px;
    font-weight: bolder;
}

.card-icon-participant img {
    height: 40px;
    width: 40px;
}

.header-icons .header-icon-right {
    position: relative;
    top: 23px;
}

.header-icons .header-icon-left {
    position: relative;
    top: 12px;
}

.card-margin {
    margin-left: var(--spectrum-alias-size-150);
    margin-left: var(--spectrum-global-dimension-size-150, var(--spectrum-alias-size-150));
    margin-right: var(--spectrum-alias-size-150);
    margin-right: var(--spectrum-global-dimension-size-150, var(--spectrum-alias-size-150));
}

.card-content-body ._spectrum-Rule_3c70b {
    margin: 0 -16px;
}

img.card-image {
    width: var(--spectrum-alias-size-900);
    width: var(--spectrum-global-dimension-size-900, var(--spectrum-alias-size-900));
    height: var(--spectrum-alias-size-900);
    height: var(--spectrum-global-dimension-size-900, var(--spectrum-alias-size-900));
    background-color: var(--spectrum-global-color-gray-400);
    border-radius: 4px;
}

.timepicker {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
}
.timepicker * {
    box-sizing: border-box;
}
.timepicker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0;
}
.timepicker-clear-icon:after {
    content: "x";
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    height: 20px;
    width: 20px;
    transition: color 0.3s ease;
}
.timepicker-clear-icon:hover:after {
    color: #666;
}

.timepicker-input {
    box-sizing: border-box;
    border: var(--spectrum-global-dimension-static-size-10) solid;
    border: var(--spectrum-alias-border-size-thin,var(--spectrum-global-dimension-static-size-10)) solid;
    border-radius: var(--spectrum-global-dimension-size-50);
    border-radius: var(--spectrum-alias-border-radius-regular,var(--spectrum-global-dimension-size-50));
    padding: 3px var(--spectrum-global-dimension-size-150) 5px calc(var(--spectrum-global-dimension-size-150) - 1px);
    text-indent: 0;
    width: 100%;
    height: var(--spectrum-global-dimension-size-400);
    height: var(--spectrum-alias-single-line-height,var(--spectrum-global-dimension-size-400));
    vertical-align: top;
    margin: 0;
    overflow: visible;
    font-family: adobe-clean-ux,adobe-clean,Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif;
    font-size: var(--spectrum-global-dimension-font-size-100);
    font-size: var(--spectrum-alias-font-size-default,var(--spectrum-global-dimension-font-size-100));
    line-height: var(--spectrum-global-font-line-height-medium);
    line-height: var(--spectrum-alias-body-text-line-height,var(--spectrum-global-font-line-height-medium));
    text-overflow: ellipsis;
    transition: border-color .13s ease-in-out,box-shadow .13s ease-in-out;
    outline: none;
    -webkit-appearance: none;
    background-color: var(--spectrum-global-color-gray-50);
    border-color: var(--spectrum-global-color-gray-400);
    border-color: var(--spectrum-alias-border-color,var(--spectrum-global-color-gray-400));
    color: var(--spectrum-global-color-gray-800);
    color: var(--spectrum-alias-text-color,var(--spectrum-global-color-gray-800));
}

.timepicker-input:focus {
    border-color: var(--spectrum-global-color-blue-500);
    border-color: var(--spectrum-alias-border-color-mouse-focus,var(--spectrum-global-color-blue-500));
}

.timepicker-input[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
}
.timepicker-panel {
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
}
.timepicker-panel * {
    box-sizing: border-box;
}
.timepicker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
}
.timepicker-panel-narrow {
    max-width: 113px;
}
.timepicker-panel-input {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: auto;
    line-height: 1.5;
    outline: 0;
    border: 1px solid transparent;
}
.timepicker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    padding: 6px;
    border-bottom: 1px solid #e9e9e9;
}
.timepicker-panel-input-invalid {
    border-color: red;
}
.timepicker-panel-select {
    float: left;
    font-size: 12px;
    border: 1px solid #e9e9e9;
    border-width: 0 1px;
    margin-left: -1px;
    box-sizing: border-box;
    width: 56px;
    max-height: 144px;
    overflow-y: auto;
    position: relative;
}
.timepicker-panel-select-active {
    overflow-y: auto;
}
.timepicker-panel-select:first-child {
    border-left: 0;
    margin-left: 0;
}
.timepicker-panel-select:last-child {
    border-right: 0;
}
.timepicker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
}
.timepicker-panel-select li {
    list-style: none;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.timepicker-panel-select li:hover {
    background: #edfaff;
}
li.timepicker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold;
}
li.timepicker-panel-select-option-disabled {
    color: #ccc;
}
li.timepicker-panel-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed;
}


.participant-map > div,
.participant-map {
    height: 100%;
    color: black;
}

.participant-map .participant-badge span {
    color: black;
}

.participant-map .two-column {
    width: 50%;
    height: calc(100% - 10px);
    grid-gap: 32px;
    gap: 32px;
}

.participant-map .two-rows {
    height: calc(50% - 16px);
}

.participant-map .participant-badge {
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    white-space: nowrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.participant-map .content-box {
    height: 100%;
}

.participant-map .room-info .scroll-container > div {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 18px;
    gap: 18px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.participant-map .buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.participant-map .topic-cards {
    width: 100%;
    height: 100%;
}

.participant-map .breakout-card {
    width: 300px;
}

.participant-map .breakout-card .card-content {
    height: 100%;
}

.participant-map .breakout-card .scroll-container{
    max-height: 250px;
}

.participant-map .breakout-card button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D2D2D2;
    color: black;
    border-radius: 4px;
}

.participant-map .topic-cards > .scroll-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 18px;
    gap: 18px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.device-settings-dialog {
    min-width: 730px;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 4px;
}

.device-settings-dialog hr {
    background-color: #404040;
}

.device-settings-dialog video {
    max-width: 396px;
    max-height: 222px;
}

.device-settings-dialog .preview-video-container {
    width: 396px;
    height: 222px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.device-settings-dialog .icon-button {
    width: 42px;
    height: 32px;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
}

.device-settings-dialog ._spectrum-ToggleSwitch-input_fc100~._spectrum-ToggleSwitch-label_fc100,
.device-settings-dialog section span,
.device-settings-dialog h3 {
    color: white !important;
}

.device-settings-dialog section button:hover,
.device-settings-dialog section button,
.device-settings-dialog input {
    background-color: #404040 !important;
    border: 1px solid #6F6F6F !important;
    border-radius: 4px !important;
    color: white !important;
}

.device-settings-dialog label,
.device-settings-dialog {
    color: white;
    background-color: #404040;
}

.device-settings-dialog ._spectrum-ToggleSwitch-switch_fc100 {
    background-color: white;
}

.device-settings-dialog footer {
    margin-top: 25px;
    padding-top: 15px;
    border-top: 1px solid #6F6F6F;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.device-settings-dialog footer ._spectrum-Button--primary_7a745 {
    background-color: white;
    border-color: var(--spectrum-global-color-gray-800);
    color: black;
}

.device-settings-dialog footer ._spectrum-Button--secondary_7a745 {
    background-color: #404040;
    border-color: white;
    color: white;
}

.device-settings-dialog footer ._spectrum-Button--primary_7a745 span {
    color: black;
}


.device-settings-dialog ._spectrum-BarLoader_c74d2._is-positive_c74d2 ._spectrum-BarLoader-fill_c74d2,
.device-settings-dialog ._spectrum-BarLoader-fill_c74d2 {
    background-color: white;
}

.device-settings-dialog ._spectrum-BarLoader-track_c74d2 {
    background-color: #6F6F6F;
}

.device-settings-dialog .meter-container {
    margin-bottom: -10px;
}

.device-settings-dialog .meter-container,
.device-settings-dialog .meter > div,
.device-settings-dialog .meter {
    width: 100%;
}

.tab-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    background-color: #EBEBEB;
    box-shadow: 0 1px 4px #00000026;
    margin:0 -24px;
}

.tab-list-item {
    font-size: 15px;
    color: black;
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
}

.tab-list-item:first-child {
    padding-left: 12px;
    margin-left: 12px;
}

.tab-list-active {
    font-weight: bold;
    border: solid var(--spectrum-global-color-gray-800);
    border-width: 0 0 3px 0;
}

.tab-content {
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: calc(100% - 55px);
}

.tab-content .scroll-container {
    border-left:none;
}

.tabs {
    margin-top: -10px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.participant-cafe-icon * {
    box-sizing: content-box;
    box-sizing: initial;
}

.group-call-box-participants {
    margin: 16px 38px;
}

.group-call-box ._spectrum-ButtonGroup_25328 {
    margin: 0 !important;
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.group-call-box .card-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.cafe {
    height: 100%;
}

.cafe .two-column > div {
    width: 50%;
    height: 100%;
}

.cafe .two-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    width: 100%;
    grid-gap: 32px;
    gap: 32px;
}

.cafe .participant-cafe-badge {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.cafe .participant-cafe-badge * {
    box-sizing: content-box;
    box-sizing: initial;
}

.cafe .participant-cafe-badge.inactive {
    opacity: 0.5;
}

.number-of-participants-circle > div,
.number-of-participants-circle {
    background-color: white;
    display: -webkit-flex;
    display: flex;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.number-of-participants-circle > div {
    width: 44px;
    height: 44px;
    border: 1px solid #ccc;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.incoming-call-dialog {
    width: 355px;
    height: 330px;

    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    background-color: #404040;
    box-shadow: 0px 1px 4px #00000026;
    border-radius: 4px;
}

.incoming-call-dialog h3 {
    color: white;
}

.incoming-call-dialog section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-image: url(/static/media/ring-animation.6f130200.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.incoming-call-dialog footer {
    border-top: 1px solid #666666;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    padding-top: 10px;
    -webkit-justify-content: center;
            justify-content: center;
}

.incoming-call-dialog .buttons {
    display: -webkit-flex;
    display: flex;
}

.incoming-call-dialog .buttons button {
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    padding: 0;
    min-width: auto;
    min-height: auto;
    height: auto;
}

.incoming-call-dialog .participant-badge {
    padding: 5px;
}

.moderated-queue-topic-generation > .content-with-toolbox-content {
    height: 100%;
    width: 100%;
}

.moderated-queue-topic-generation > .content-with-toolbox-content > div {
    height: 100%;
}

.moderated-queue-topic-generation > .content-with-toolbox-content > div  > div {
    height: 100%;
    width: 50%;
}

.moderated-queue-topic-generation .right-content-column {
    width: 100%;
    height: 100%;
    border-left: 1px solid #CDCDCE;
    padding-left: 25px;
}

.moderated-queue-topic-generation .pitch-topic-content {
    width: 100%;
    min-height: 50%;
}

.moderated-queue-topic-generation .topic-preview .card-title,
.moderated-queue-topic-generation .pitch-topic-content .card-title {
    font-size: 32px;
}

.moderated-queue-topic-generation .topic-preview .topic-card,
.moderated-queue-topic-generation .pitch-topic-content .topic-card {
    margin-top: 10px;
    width: 100%;
}

.moderated-queue-topic-generation .topic-preview .card-icon-participant img,
.moderated-queue-topic-generation .pitch-topic-content .card-icon-participant img {
    height: 50px;
    width: 50px;
}

.moderated-queue-topic-generation .topic-preview .card-icon-participant,
.moderated-queue-topic-generation .pitch-topic-content .card-icon-participant {
    height: 56px;
    width: 56px;
}

.moderated-queue-topic-generation .topic-preview .card-icon-participant > div,
.moderated-queue-topic-generation .pitch-topic-content .card-icon-participant > div {
    height: 50px;
    width: 50px;
}

.moderated-queue-topic-generation .topic-preview .header-icons,
.moderated-queue-topic-generation .pitch-topic-content .header-icons {
    width: 95%;
}

.moderated-queue-topic-generation .topic-preview .header-icons .header-icon-right,
.moderated-queue-topic-generation .pitch-topic-content .header-icons .header-icon-right {
    top: 28px;
}

.moderated-queue-topic-generation .up-next {
    color: black;
}

.moderated-queue-topic-generation .topic-preview {
    width: 100%;
}

.moderated-queue .moderator-button {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    border-color: #6F6F6F;
}

.moderated-queue .moderator-button:hover {
    border-color: white;
}

.moderated-queue .moderator-button span {
    font-size: 13px;
    font-weight: normal;
}

.card-icon-topic-voting {
    margin-left: var(--spectrum-alias-size-200);
    margin-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    margin-right: var(--spectrum-alias-size-200);
    margin-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--spectrum-global-color-gray-200);
    width: 24px;
    height: 24px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.card-icon-topic-voting-accepted {
    color: black;
}

.card-icon-topic-voting-closed {
    color: black;
}

.participant-circle.topic-participant {
    display: -webkit-flex;
    display: flex;
    height: 30px;
    width: 30px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.participant-circle.topic-participant > div {
    display: -webkit-flex;
    display: flex;
    height: 26px;
    width: 26px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.topic-participant img {
    height: 26px;
    width: 26px;
}

.topic-discussing-participants {
    color: var(--spectrum-global-color-gray-600);
}

.topic-discussing-participants .participant-circle {
    border: none;
    width: 26px;
    height: 26px;
}

.topic-discussing-participants .participant-circle img {
    width: 26px;
    height: 26px;
}

.topic-cards {
    margin-top: 10px;
    -webkit-align-self: baseline;
            align-self: baseline;
}

.topic-card {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.topic-card hr {
    margin-top: 8px !important;
    margin-bottom: 12px !important;
}

.topic-card .card-content .description {
    overflow: hidden;
}

.topic-card .card-content-body > div hr:nth-of-type(2) {
    margin-top: 7px !important;
    margin-bottom: 5px !important;
}

.topic-card .card-content-body > div hr:first-of-type {
    margin-top: 13px !important;
    margin-bottom: 7px !important;
}

.topic-participant-icons {
    margin-top: 5px;
}

.participant-circle-with-label .participant-circle {
    z-index: 2;
}

.participant-circle-with-label .participant-circle img {
    height: 40px;
    width: 40px;
}

.participant-circle-with-label .label {
    padding: 5px 10px 5px 20px;
    margin-left: -10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #00000000;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    z-index: 1;
}

.no-active-phase {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.no-active-phase .logo {
    width: 432px;
    height: 432px;
}

.topic-note {
    white-space: pre-line;
    padding: 10px;
    background: #E0E0E0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    max-width: 20vw;
    word-wrap: anywhere;
    font-size: 18px;
}

.topic-note textarea {
    font-size: 18px;
}

.topic-note a {
    cursor: pointer;
}

.topic-note .note-controls {
    margin-top: 10px;
}

.topic-room-header-closed {
    margin-left: var(--spectrum-alias-size-200);
    margin-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    margin-right: var(--spectrum-alias-size-200);
    margin-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--spectrum-global-color-gray-200);
    width: 24px;
    height: 24px;
    background-color: var(--spectrum-global-color-gray-200);
    color: red;
    margin-top: 6px;
}

.topic-room-header-accepted {
    margin-left: var(--spectrum-alias-size-200);
    margin-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    margin-right: var(--spectrum-alias-size-200);
    margin-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--spectrum-global-color-gray-200);
    width: 24px;
    height: 24px;
    background-color: var(--spectrum-global-color-gray-200);
    color: green;
    margin-top: 6px;
}

.topic-room-header-info {
    font-weight: bolder;
    font-size: 24px;
}

.topic-description-container-row, .whiteboard-container-row {
    width: 100%;
    color: black;
}

.topic-room .row-layout > div,
.topic-room .row-layout {
    width: 100%;
}

.topic-description-container-row button {
    background-color: transparent;
    color:black;
}


.workshop-designer .phase {
    background-color: white;
    padding: 5px 10px;
    border: none !important;
    box-shadow: 0 1px 4px #00000026;
}

.workshop-designer .phase h3 {
    padding-top: 0;
    margin-top: 0;
    color: black;
}

.workshop-designer .phase .duration {
    font-weight: bold;
    font-size: large;
    margin: 10px 0;
}

.workshop-designer .phase .time .rc-time-picker input,
.workshop-designer .phase .time .rc-time-picker .rc-time-picker-icon {
    display: none;
}

.workshop-designer .phase .time a > span {
    background: #acacac;
    color: white;
    font-size: smaller;
    padding: 1px 5px;
    /* do not use shorthand property (result looks different) */
    border-top-left-radius: 10px 50%;
    border-top-right-radius: 10px 50%;
    border-bottom-left-radius: 10px 50%;
    border-bottom-right-radius: 10px 50%;
}

.workshop-designer a {
    cursor: pointer;
}

.workshop-designer .phase .time:first-child {
    margin-top: 10px;
}

.workshop-designer .phase .title-column a.save-icon {
    margin-top: 3px;
}

.workshop-designer .phase .title-column {
    width: 15%;
}

.workshop-designer .phase .plenary-column,
.workshop-designer .phase .topic-room-column
{
    width: 40%;
    border-left: 1px solid #E6E6E6;
}

.workshop-designer .phase .title-column {
    border-left: 1px solid #E6E6E6;
}

.workshop-designer .phase .title-column,
.workshop-designer .phase .room-config-header,
.workshop-designer .phase .room-config-container
{
    padding: 5px 10px;
}

.topic-room-column .room-config-container button{
    background-color: transparent;
}

.topic-room-column .room-config-container button:hover {
    background-color: var(--spectrum-global-color-gray-900);
}

.topic-room-column .room-config-container button:hover span {
    color: white;
}

.workshop-designer .phase .time-column {
    display: -webkit-flex;
    display: flex;
    width: 5%;
    max-width: 100px;
    -webkit-justify-content: center;
            justify-content: center;
}

.workshop-designer .phase .time-column .controls {
    margin-top: auto;
}

.workshop-designer .phase .room-config-header {
    border-bottom: 1px solid #E6E6E6;
    font-weight: bold;
}

.workshop-designer span {
    color: black;
}

.workshop-designer .phase .mode-of-interaction-label {
    margin-top: 6px;
    margin-right: 10px;
    color: black;
}


.invitation-line {
    display: inline-block;
    width: 840px;
    height: 24px;
}

.invitation-line-email {
    width: 400px;
    display: inline-block;
}

.invitation-line-separator {
    margin-left: 16px;
    margin-right: 16px;
    display: inline-block;
}

.invitation-line-nickname {
    width: 400px;
    display: inline-block;
}

.participant-invitation-manager {
    height: 100%;
}

.participant-invitation-manager > .scroll-container {
    width: 50%;
}

.participant-invitation-manager .card .additional-button-divider {
    margin-top: 16px !important;
}

.participant-invitation-manager .participant-circle {
    background-color: #D3D3D3;
}

.toolbox-controls {
    margin: auto 0;
    text-align: center;
}

.main-frame {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.drawer-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

/**
LEFT
 */

.menu-left {
    background: black;
    color: white;
    height: 100%;
    box-shadow: 0 13px 16px #00000029;
}

.menu-left a {
    color: white;
}

.menu-top {
    border-bottom: 1px solid #3E3E3E;
    padding-bottom: 4px;
}

div.menu-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 5px 0;
    cursor: default;
}

div.menu-item-icon {
    height: 40px;
    width: 40px;
    margin-left: 4px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

div.logout-button {
    margin-top: 25px;
}

div.menu-item-disabled {
    display: -webkit-flex;
    display: flex;
    color: #5d5d5d;
}

.menu-item-icon .schedule-menu-item-done {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

div.menu-item-content {
    padding-left: 11px;
    padding-right: 11px;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    width: 220px;
}

.icon-active ~ .menu-item-content a {
    font-weight: bolder;
}

.menu-middle {
    padding-top: 10px;
    height: inherit;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.menu-middle::-webkit-scrollbar {
    display: none;
}

.menu-middle .vertical-line {
    width: 40px;
    margin: 0 4px;
    display: -webkit-flex;
    display: flex;
    height: 22px;
    -webkit-justify-content: center;
            justify-content: center;
}

.menu-middle .vertical-line.adjust-to-small-icon {
    margin-top: -1px;
}

.menu-middle .vertical-line.adjust-to-next-time {
    margin-bottom: -1px;
}

.menu-middle .vertical-line > div {
    width: 1px;
    background: #818181;
    height: 100%;
}

.menu-middle .menu-item {
    margin-top: 0;
    margin-bottom: 0;
}

.menu-middle .menu-item-icon {
    height: 19px;
}

.menu-middle .menu-item-link {
    max-width: 220px;
}

.menu-bottom {
    border-top: 1px solid #3E3E3E;
    justify-self: end;
    padding-top: 4px;
    width: 100%;
}

.menu-bottom .profile-icon > div:first-of-type {
    width: 32px;
    height: 32px;
    margin-left: 8px;
}

.menu-bottom .profile-icon > div:first-of-type img {
    width: 32px;
    height: 32px;
}

.menu-bottom .profile-icon .menu-item-content {
    padding-left: 15px;
}

/**
RIGHT
 */
.menu-right {
    background: #4B4B4B;
    color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    transition: all 0.3s linear;
}

.menu-right a {
    color: black;
}

.menu-right.search-drawer {
    border-left: 1px solid #6F6F6F;
}

.menu-right-content {
    width: inherit;
}

.menu-right-notifications {
    padding-top: 10px;
    background: black;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.clickable-icon {
    padding: 20px;
    border-radius: 4px;
    -moz-border-radius: 4px;
}

.clickable-icon:hover {
    cursor: pointer;
    background-color: #4B4B4B;
}

.clickable-icon.icon-active {
    background-color: #4B4B4B;
}

.clickable-icon.icon-active:hover {
    background-color: #6f6f6f;
}

.content-frame {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #F1F2F3;
    width: 100%;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.content-with-toolbox {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.content-with-toolbox-content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
    padding-bottom: 10px;
}

.content-with-toolbox-content .return-to-plenary-btn {
    background-color: transparent;
    color:black;
}

.content-with-toolbox-content::-webkit-scrollbar {
    display: none;
}

.drawer-hidden {
    width: 0;
}

.search-participants {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.search-participants > div {
    width: 100%;
}

.search-participants > div:first-child {
    margin-top: 15px;
    margin-bottom: 5px;
    width: 90%;
}

.search-participants > div:first-child svg * {
    fill: white;
}


.search-participants input::-webkit-input-placeholder, .search-participants input:active::-webkit-input-placeholder {
    color: white !important;
}


.search-participants input::placeholder,
.search-participants input:active::placeholder,
.search-participants input::-webkit-input-placeholder,
.search-participants input::-ms-input-placeholder,
.search-participants input:-ms-input-placeholder,
.search-participants input:-moz-placeholder,
.search-participants input::-moz-placeholder,
.search-participants .is-hovered_61339 {
    color: white !important;
}

.search-participants input:-ms-input-placeholder {
    font-size: 13px;
    width: 100%;
    color: white !important;
    background-color: #4B4B4B;
    border-color: #6F6F6F;
}

.search-participants input:focus,
.search-participants input:hover,
.search-participants input:active,
.search-participants input:placeholder-shown,
.search-participants input {
    font-size: 13px;
    width: 100%;
    color: white !important;
    background-color: #4B4B4B;
    border-color: #6F6F6F;
}

.search-participants hr {
    background-color: #6F6F6F;
}

.search-participants .search-results {
    padding-top: 10px;
}

.search-participants .search-result {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    color: white;
    grid-gap: 10px;
    gap: 10px;
    padding: 0 10px;
}

.search-participants .search-result .description {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

.search-participants .search-result .description .participant-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 2px;
}

.search-participants .search-result .description .participant-location {
    font-size: 11px;
}

.search-participants .search-result .actions {
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.search-participants .search-result .icon-button {
    padding: 12px;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    height: 24px;
    width: 32px;
    background-size: 14px;
}

.search-participants .search-result .icon-button:hover {
    background-color: #a0a0a0;
    cursor: pointer;
}

.search-participants .search-result .actions button {
    color: white;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
}

.breadcrumb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.breadcrumb-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 15px;
    color: black;
}

.breadcrumb-item:first-child{
    padding-left: 24px;
}

.breadcrumb-item:last-child {
    font-weight: bold;
}

.breadcrumb-item:not(:first-child):before{
    content:"";
    background-image: url(/static/media/chevron-right.0e524396.svg);
    width: 18px;
    height: 18px;
    margin: 0 4px 0 4px;
}

.schedule-menu-item-title {
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
}

.active-phase .schedule-menu-item-title {
    margin-bottom: 12px;
}

.schedule-menu-item-role {
    font-weight: normal;
    margin-bottom: 1ex;
}

.schedule-menu-item-time-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

div.schedule-menu-item-time {
    color: #818181;
    border-style: solid;
    border-width: 1px;
    border-radius: 9px;
    background: inherit 0 0 no-repeat padding-box;
    padding: 2px 2px;
    width: 40px;
    height: 17px;
    font-weight: normal;
    font-size: 11px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

div.schedule-menu-item-done {
    width: 56px;
}

.menu-middle .menu-variant-full.active-phase {
    background: #4B4B4B 0 0 no-repeat padding-box;
    border-radius: 4px;
    -webkit-align-items: start;
            align-items: start;
    padding: 12px 0;
    margin-right: 5px;
}

.menu-middle .menu.item {
    margin-top: 0;
    margin-bottom: 0;
}

.menu-middle .inactive-phase .menu-item-content {
    height: 19px;
}

.menu-middle .menu-variant-full.active-phase .schedule-menu-item-time {
    color: black;
    border-color: #FFFFFF;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 9px;
}

.menu-middle .menu-variant-icon.active-phase .schedule-menu-item-time {
    color: white;
    border-color: #4B4B4B;
    background: #4B4B4B 0 0 no-repeat padding-box;
    border-radius: 9px;
}

.menu-middle .active-phase button {
    background-color: transparent;
    font-size: 11px;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    font-weight: normal;
    padding-right: 7px;
    padding-left: 7px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 200px;
}

.menu-middle .active-phase button:hover {
    background-color: var(--spectrum-global-color-gray-900);
}

.meeting-controls {
    display: -webkit-inline-flex;
    display: inline-flex;
    grid-gap: 7px;
    gap: 7px;
    -webkit-justify-content: center;
            justify-content: center;
}

.meeting-controls button {
    border-color: #6f6f6f;
}

.meeting-controls button:hover {
    border-color: white;
}
.roster {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.roster::-webkit-scrollbar {
    display: none;
}

/**


 */
.video-container-stage {
    height: 109px;
}

.video-container-participants video,
.video-container-stage video {
    object-fit: cover;
}

.video-container-participants video {
    display: block;
    width: 164px;
    height: 94px;
}

.video-container-stage video {
    display: block;
    width: 192px;
    height: 109px;
}

.video-container-presenter .video-controls,
.video-container-stage .video-controls,
.video-container-participants .video-controls {
    position: relative;
}

.video-control-icon-container {
    position: absolute;
}

.video-container-stage .video-role-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: relative;
    color: white;
    z-index: 100000;
}

.video-container-stage .video-role {
    font-size: 11px;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    height: 25px;
    text-shadow: 0 1px 0 black;
    border-bottom-left-radius: 13px;
}

.video-container-presenter .video-nameplate,
.video-container-stage .video-nameplate,
.video-container-participants .video-nameplate {
    font-size: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    bottom: 18px;
    margin-bottom: -18px;
    height: 18px;
    color: white;
    padding-left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
}

.video-container-presenter, .video-container-presenter > video {
    display: block;
    width: 39vw;
    height: 22vw;
}

.video-container-presenter-text {
    color: white;
    text-align: center;
    position: relative;
    top: 50px;
}

.video-container-presenter-content-share {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.video-container-presenter-content-share video {
    width: 95%;
    max-height: 95%;
    max-width: 95%;
    object-fit: cover;
}

.video-controls {
    position: relative;
    z-index: 1000000000;
}

.video-control-icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 1000000000;
}

.participant-video-avatar {
    text-align: center;
    background: black;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 192px;
    height: 109px;
}

.video-container-presenter .participant-video-avatar {
    width: 50vw;
    height: 28vw;
}

.participant-video-avatar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.participant-video-avatar .avatar-icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.participant-video-avatar .avatar-icon-container > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #8C8A91;
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.participant-video-avatar img {
    width: 80px;
    height: 80px;
}

.video-container-presenter .participant-video-avatar img {
    width: 200px;
    height: 200px;
}

.participant-video-avatar-content {
    width: 250px;
    height: 120px;
    padding-top: 25px;
    overflow: hidden;
}

.video-container-presenter.offline .participant-icon > div {
    width: 200px;
    height: 200px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.video-container-presenter.offline .participant-icon {
    width: 10vw;
    height: 10vw;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.video-container-presenter.offline .participant-icon img {
    width: 10vw;
    height: 10vw;
}

.video-container-presenter.offline .participant-badge .icon-symbol {
    width: 40px;
    height: 40px;
    font-size: 18px;
    left: 53px;
    top: 45px;
}

.video-container-presenter.offline .participant-badge-name {
    font-size: 18px;
}

.video-container-stage.offline > div,
.video-container-presenter.offline > div,
.video-container-participants.offline > div {
    background-color: #646464;
}

.video-container-stage.offline .avatar-icon-container,
.video-container-stage.offline .participant-video-avatar img,
.video-container-presenter.offline .avatar-icon-container,
.video-container-presenter.offline .participant-video-avatar img,
.video-container-participants.offline .avatar-icon-container,
.video-container-participants.offline .participant-video-avatar img {
    opacity: 0.5;
}





.roster-grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-left: 1px solid #6F6F6F;
    height: 100%;
}

.roster-grid-header {
    color: white;
    -webkit-flex: none;
            flex: none;
    border-bottom: 1px solid #6F6F6F;
    display: -webkit-flex;
    display: flex;
    padding: 10px;
}

.roster-grid-stage {
    min-height: 135px;
    -webkit-flex: none;
            flex: none;
    border-bottom: 1px solid #6F6F6F;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.roster-grid-stage .light-text {
    font-size: 11px;
}

.roster-grid-participants {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
}

.roster-grid-participants hr {
    background-color: #6F6F6F;
    width: 85px;
    margin: 0 auto !important;
    height: 1px !important;
}

.presentation-meeting-container .roster-grid-participants {
    width: 218px;
}

.roster-grid-participants::-webkit-scrollbar {
    display: none;
}

.roster-grid-moderator-controls {
    padding: 10px;
    -webkit-flex: none;
            flex: none;
}

.roster-grid-moderator-controls > button {
    background-color: transparent;
    color: white;
}

.roster-grid-video-controls {
    padding: 10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.roster-grid-local-video {
    padding-top: 10px;
    -webkit-flex: none;
            flex: none;
}

.roster-grid-local-video video {
    width: 192px;
    height: 109px;
}

.video-placeholder-stage {
    width: 192px;
    height: 109px;
}

.open-meeting-roster {
    padding: 0 14px;
}

.open-meeting-roster .participant-video-avatar, .open-meeting-roster video {
    width: 142px;
    height: 80px;
}
.presentation-meeting-container {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.presentation-meeting-container > div:first-child {
    -webkit-flex-grow: 2;
            flex-grow: 2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.presentation-meeting-container .video-container-presenter, .video-container-presenter .participant-video-avatar {
    width: 39vw;
    height: 22vw;
}

.video-container-presenter .participant-video-avatar img {
    width: 28%;
    height: 50%;
}

.presentation-meeting-roster-container {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: end;
            justify-content: end;
}

.presentation-meeting-roster-container .roster-grid-stage {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.moderated-with-roles .moderated-with-roles-inactive-role-icon img {
    width: 50px;
    height: 50px;
}

.moderated-with-roles .moderated-with-roles-role-label {
    color: white;
    text-align: center;
    font-size: 11px;
    margin-bottom: 5px;
}

.moderated-with-roles .inactive-role-column {
    width: 35%;
    height: 100%;
    margin-right: 10px;
}

.moderated-with-roles .roster-grid-participants {
    border-top: none;
    overflow-y: visible;
    height: 100%;
}

.moderated-with-roles .active-role-roster {
    width: 330px;
    height: calc(100% - 50px);
    margin: 12px;
    margin-right: 10px;
}

.moderated-with-roles .active-role-roster .participant-video-avatar {
    width: 164px;
    height: 94px;
}

.moderated-with-roles .video-container-stage {
    height: auto;
}

.moderated-with-roles .video-container-stage .participant-video-avatar {
    width: 143px;
    height: 82px;
}

.roster-sidebar > .moderated-with-roles {
    height: 100%;
}

.roster-grid-participants > .moderated-with-roles {
    height: 100%;
}

.moderated-with-roles .active-role-column .active-role-roster,
.moderated-with-roles .inactive-role-column .inactive-role-roster {
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.moderated-with-roles .roster-grid-stage {
    border-bottom: none;
}

.moderated-with-roles .active-role-roster .local-video-active {
    width: 164px;
    height: 94px;
}

.moderated-with-roles .active-role-column ._spectrum-Rule_3c70b {
    width: 332px;
    margin-top: 5px;
}

.moderated-with-roles .inactive-role-column .inactive-role-roster {
    text-align: center;
}

.moderated-with-roles .active-role-column .active-role-roster::-webkit-scrollbar,
.moderated-with-roles .inactive-role-column .inactive-role-roster::-webkit-scrollbar {
    display: none;
}

.moderated-with-roles .inactive-role-column .inactive-role-roster {
    height: calc(100% - 100px);
}

.moderated-with-roles .inactive-role-column .networkers-role-roster-label {
    font-size: 11px;
    margin-bottom: 5px;
}
.moderated-with-roles .inactive-role-column .networkers-role-roster {
    height: 70px;
    margin-top: 15px;
}

.moderated-with-roles .roster-grid-local-video video {
    width: 250px;
    height: auto;
}

.local-video {
    border: 1px solid #112233;
    width: 300px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.local-video-inactive {
    display: none;
}

.default-video-grid, .default-video-grid div {
    height: 100%;
}

.full-height-grid {
    height: 100%;
}

.roster-sidebar {
    border-left: 1px solid #6F6F6F;
}

.video-container {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.moderated-queue {
    height: 100%;
}

.moderated-queue .roster-grid-participants > div {
    width: 130px;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.moderated-queue .roster-grid-participants > div::-webkit-scrollbar {
    display: none;
}

.moderated-queue .roster-grid-participants,
.moderated-queue .queued-participants,
.moderated-queue .roster-grid-stage {
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
}

.moderated-queue .up-next-label {
    margin-top: 20px;
    margin-bottom: 5px;
    color: white;
    text-align: center;
    font-size: 11px;
}

.moderated-queue .up-next .accept-topic {
    margin-top: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.moderated-queue .roster-grid-moderator-controls .hover-button-icon {
    height: 20px;
    width: 30px;
}

.leave-call-button {
    background-color: transparent;
    border-color: #6f6f6f;
    color: white;
    padding: 6px;
}
.leave-call-button:hover {
    background-color: transparent;
    border-color: white;
    color: white;
}

.group-call-roster {
    padding: 0 12px;
}

.group-call-roster .participant-video-avatar {
    width: 216px;
    height: 122px;
}

.group-call-roster .video-container-participants video {
    width: 216px;
    height: 122px;
}
html {
  font-size: 16px;
}

body {
  margin: 0;
  padding:0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

html, #root, #root > div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._spectrum-Button--secondary_7a745 {
  background-color: transparent;
  background-color: var(--spectrum-alias-background-color-transparent,transparent);
  border-color: var(--spectrum-global-color-gray-800);
  color: var(--spectrum-global-color-gray-800);
}

._spectrum-Button--primary_7a745 {
  background-color: var(--spectrum-global-color-gray-900);
  border-color: var(--spectrum-global-color-gray-900);
  color: #fff;
  color: var(--spectrum-global-color-static-white,#fff);
}
._spectrum-Button--cta_7a745 {
  background-color: var(--spectrum-global-color-static-blue-600);
  background-color: var(--spectrum-semantic-cta-color-background-default,var(--spectrum-global-color-static-blue-600));
  border-color: var(--spectrum-global-color-static-blue-600);
  border-color: var(--spectrum-semantic-cta-color-background-default,var(--spectrum-global-color-static-blue-600));
  color: #fff;
  color: var(--spectrum-global-color-static-white,#fff);
}

._spectrum-Underlay_8c345 {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

._spectrum-Dialog-content_30293 a{
  color: #4B4B4B;
}

.participant-icon {
  display: -webkit-flex;
  display: flex;
  height: 32px;
  width: 32px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.participant-icon > div {
  display: -webkit-flex;
  display: flex;
  height: 32px;
  width: 32px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.participant-icon img {
  border: 2px solid white;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

button:hover .hover-button-icon {
  -webkit-filter: invert(75%);
          filter: invert(75%);
}

.light-text {
  color: white;
}

.konvajs-content {
  border-left: 1px solid #3E3E3E;
  border-right: 1px solid #3E3E3E;
}

