.roster-grid {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #6F6F6F;
    height: 100%;
}

.roster-grid-header {
    color: white;
    flex: none;
    border-bottom: 1px solid #6F6F6F;
    display: flex;
    padding: 10px;
}

.roster-grid-stage {
    min-height: 135px;
    flex: none;
    border-bottom: 1px solid #6F6F6F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roster-grid-stage .light-text {
    font-size: 11px;
}

.roster-grid-participants {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
}

.roster-grid-participants hr {
    background-color: #6F6F6F;
    width: 85px;
    margin: 0 auto !important;
    height: 1px !important;
}

.presentation-meeting-container .roster-grid-participants {
    width: 218px;
}

.roster-grid-participants::-webkit-scrollbar {
    display: none;
}

.roster-grid-moderator-controls {
    padding: 10px;
    flex: none;
}

.roster-grid-moderator-controls > button {
    background-color: transparent;
    color: white;
}

.roster-grid-video-controls {
    padding: 10px 0;
    display: flex;
    justify-content: center;
}

.roster-grid-local-video {
    padding-top: 10px;
    flex: none;
}

.roster-grid-local-video video {
    width: 192px;
    height: 109px;
}
