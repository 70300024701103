.incoming-call-dialog {
    width: 355px;
    height: 330px;

    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #404040;
    box-shadow: 0px 1px 4px #00000026;
    border-radius: 4px;
}

.incoming-call-dialog h3 {
    color: white;
}

.incoming-call-dialog section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-image: url(ring-animation.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.incoming-call-dialog footer {
    border-top: 1px solid #666666;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 10px;
    justify-content: center;
}

.incoming-call-dialog .buttons {
    display: flex;
}

.incoming-call-dialog .buttons button {
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    padding: 0;
    min-width: auto;
    min-height: auto;
    height: auto;
}

.incoming-call-dialog .participant-badge {
    padding: 5px;
}
