.timepicker {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
}
.timepicker * {
    box-sizing: border-box;
}
.timepicker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0;
}
.timepicker-clear-icon:after {
    content: "x";
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    height: 20px;
    width: 20px;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
}
.timepicker-clear-icon:hover:after {
    color: #666;
}

.timepicker-input {
    box-sizing: border-box;
    border: var(--spectrum-alias-border-size-thin,var(--spectrum-global-dimension-static-size-10)) solid;
    border-radius: var(--spectrum-alias-border-radius-regular,var(--spectrum-global-dimension-size-50));
    padding: 3px var(--spectrum-global-dimension-size-150) 5px calc(var(--spectrum-global-dimension-size-150) - 1px);
    text-indent: 0;
    width: 100%;
    height: var(--spectrum-alias-single-line-height,var(--spectrum-global-dimension-size-400));
    vertical-align: top;
    margin: 0;
    overflow: visible;
    font-family: adobe-clean-ux,adobe-clean,Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif;
    font-size: var(--spectrum-alias-font-size-default,var(--spectrum-global-dimension-font-size-100));
    line-height: var(--spectrum-alias-body-text-line-height,var(--spectrum-global-font-line-height-medium));
    text-overflow: ellipsis;
    transition: border-color .13s ease-in-out,box-shadow .13s ease-in-out;
    outline: none;
    -webkit-appearance: none;
    background-color: var(--spectrum-global-color-gray-50);
    border-color: var(--spectrum-alias-border-color,var(--spectrum-global-color-gray-400));
    color: var(--spectrum-alias-text-color,var(--spectrum-global-color-gray-800));
}

.timepicker-input:focus {
    border-color: var(--spectrum-alias-border-color-mouse-focus,var(--spectrum-global-color-blue-500));
}

.timepicker-input[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
}
.timepicker-panel {
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
}
.timepicker-panel * {
    box-sizing: border-box;
}
.timepicker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
}
.timepicker-panel-narrow {
    max-width: 113px;
}
.timepicker-panel-input {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: auto;
    line-height: 1.5;
    outline: 0;
    border: 1px solid transparent;
}
.timepicker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    padding: 6px;
    border-bottom: 1px solid #e9e9e9;
}
.timepicker-panel-input-invalid {
    border-color: red;
}
.timepicker-panel-select {
    float: left;
    font-size: 12px;
    border: 1px solid #e9e9e9;
    border-width: 0 1px;
    margin-left: -1px;
    box-sizing: border-box;
    width: 56px;
    max-height: 144px;
    overflow-y: auto;
    position: relative;
}
.timepicker-panel-select-active {
    overflow-y: auto;
}
.timepicker-panel-select:first-child {
    border-left: 0;
    margin-left: 0;
}
.timepicker-panel-select:last-child {
    border-right: 0;
}
.timepicker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
}
.timepicker-panel-select li {
    list-style: none;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.timepicker-panel-select li:hover {
    background: #edfaff;
}
li.timepicker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold;
}
li.timepicker-panel-select-option-disabled {
    color: #ccc;
}
li.timepicker-panel-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed;
}
