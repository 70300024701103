.main-frame {
    display: flex;
    flex-direction: row;
}

.drawer-left {
    display: flex;
    flex-direction: column;
}

/**
LEFT
 */

.menu-left {
    background: black;
    color: white;
    height: 100%;
    box-shadow: 0 13px 16px #00000029;
}

.menu-left a {
    color: white;
}

.menu-top {
    border-bottom: 1px solid #3E3E3E;
    padding-bottom: 4px;
}

div.menu-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: default;
}

div.menu-item-icon {
    height: 40px;
    width: 40px;
    margin-left: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

div.logout-button {
    margin-top: 25px;
}

div.menu-item-disabled {
    display: flex;
    color: #5d5d5d;
}

.menu-item-icon .schedule-menu-item-done {
    display: flex;
    justify-content: center;
}

div.menu-item-content {
    padding-left: 11px;
    padding-right: 11px;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    width: 220px;
}

.icon-active ~ .menu-item-content a {
    font-weight: bolder;
}

.menu-middle {
    padding-top: 10px;
    height: inherit;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.menu-middle::-webkit-scrollbar {
    display: none;
}

.menu-middle .vertical-line {
    width: 40px;
    margin: 0 4px;
    display: flex;
    height: 22px;
    justify-content: center;
}

.menu-middle .vertical-line.adjust-to-small-icon {
    margin-top: -1px;
}

.menu-middle .vertical-line.adjust-to-next-time {
    margin-bottom: -1px;
}

.menu-middle .vertical-line > div {
    width: 1px;
    background: #818181;
    height: 100%;
}

.menu-middle .menu-item {
    margin-top: 0;
    margin-bottom: 0;
}

.menu-middle .menu-item-icon {
    height: 19px;
}

.menu-middle .menu-item-link {
    max-width: 220px;
}

.menu-bottom {
    border-top: 1px solid #3E3E3E;
    justify-self: end;
    padding-top: 4px;
    width: 100%;
}

.menu-bottom .profile-icon > div:first-of-type {
    width: 32px;
    height: 32px;
    margin-left: 8px;
}

.menu-bottom .profile-icon > div:first-of-type img {
    width: 32px;
    height: 32px;
}

.menu-bottom .profile-icon .menu-item-content {
    padding-left: 15px;
}

/**
RIGHT
 */
.menu-right {
    background: #4B4B4B;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    transition: all 0.3s linear;
}

.menu-right a {
    color: black;
}

.menu-right.search-drawer {
    border-left: 1px solid #6F6F6F;
}

.menu-right-content {
    width: inherit;
}

.menu-right-notifications {
    padding-top: 10px;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
}

.clickable-icon {
    padding: 20px;
    border-radius: 4px;
    -moz-border-radius: 4px;
}

.clickable-icon:hover {
    cursor: pointer;
    background-color: #4B4B4B;
}

.clickable-icon.icon-active {
    background-color: #4B4B4B;
}

.clickable-icon.icon-active:hover {
    background-color: #6f6f6f;
}

.content-frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F1F2F3;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.content-with-toolbox {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.content-with-toolbox-content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
    padding-bottom: 10px;
}

.content-with-toolbox-content .return-to-plenary-btn {
    background-color: transparent;
    color:black;
}

.content-with-toolbox-content::-webkit-scrollbar {
    display: none;
}

.drawer-hidden {
    width: 0;
}
