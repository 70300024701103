.local-video {
    border: 1px solid #112233;
    width: 300px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.local-video-inactive {
    display: none;
}

.default-video-grid, .default-video-grid div {
    height: 100%;
}

.full-height-grid {
    height: 100%;
}

.roster-sidebar {
    border-left: 1px solid #6F6F6F;
}

.video-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
