.moderated-queue-topic-generation > .content-with-toolbox-content {
    height: 100%;
    width: 100%;
}

.moderated-queue-topic-generation > .content-with-toolbox-content > div {
    height: 100%;
}

.moderated-queue-topic-generation > .content-with-toolbox-content > div  > div {
    height: 100%;
    width: 50%;
}

.moderated-queue-topic-generation .right-content-column {
    width: 100%;
    height: 100%;
    border-left: 1px solid #CDCDCE;
    padding-left: 25px;
}

.moderated-queue-topic-generation .pitch-topic-content {
    width: 100%;
    min-height: 50%;
}

.moderated-queue-topic-generation .topic-preview .card-title,
.moderated-queue-topic-generation .pitch-topic-content .card-title {
    font-size: 32px;
}

.moderated-queue-topic-generation .topic-preview .topic-card,
.moderated-queue-topic-generation .pitch-topic-content .topic-card {
    margin-top: 10px;
    width: 100%;
}

.moderated-queue-topic-generation .topic-preview .card-icon-participant img,
.moderated-queue-topic-generation .pitch-topic-content .card-icon-participant img {
    height: 50px;
    width: 50px;
}

.moderated-queue-topic-generation .topic-preview .card-icon-participant,
.moderated-queue-topic-generation .pitch-topic-content .card-icon-participant {
    height: 56px;
    width: 56px;
}

.moderated-queue-topic-generation .topic-preview .card-icon-participant > div,
.moderated-queue-topic-generation .pitch-topic-content .card-icon-participant > div {
    height: 50px;
    width: 50px;
}

.moderated-queue-topic-generation .topic-preview .header-icons,
.moderated-queue-topic-generation .pitch-topic-content .header-icons {
    width: 95%;
}

.moderated-queue-topic-generation .topic-preview .header-icons .header-icon-right,
.moderated-queue-topic-generation .pitch-topic-content .header-icons .header-icon-right {
    top: 28px;
}

.moderated-queue-topic-generation .up-next {
    color: black;
}

.moderated-queue-topic-generation .topic-preview {
    width: 100%;
}

.moderated-queue .moderator-button {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    border-color: #6F6F6F;
}

.moderated-queue .moderator-button:hover {
    border-color: white;
}

.moderated-queue .moderator-button span {
    font-size: 13px;
    font-weight: normal;
}
