.roster {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.roster::-webkit-scrollbar {
    display: none;
}
