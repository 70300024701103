.no-active-phase {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-active-phase .logo {
    width: 432px;
    height: 432px;
}
