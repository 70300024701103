html {
  font-size: 16px;
}

body {
  margin: 0;
  padding:0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

html, #root, #root > div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._spectrum-Button--secondary_7a745 {
  background-color: var(--spectrum-alias-background-color-transparent,transparent);
  border-color: var(--spectrum-global-color-gray-800);
  color: var(--spectrum-global-color-gray-800);
}

._spectrum-Button--primary_7a745 {
  background-color: var(--spectrum-global-color-gray-900);
  border-color: var(--spectrum-global-color-gray-900);
  color: var(--spectrum-global-color-static-white,#fff);
}
._spectrum-Button--cta_7a745 {
  background-color: var(--spectrum-semantic-cta-color-background-default,var(--spectrum-global-color-static-blue-600));
  border-color: var(--spectrum-semantic-cta-color-background-default,var(--spectrum-global-color-static-blue-600));
  color: var(--spectrum-global-color-static-white,#fff);
}

._spectrum-Underlay_8c345 {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

._spectrum-Dialog-content_30293 a{
  color: #4B4B4B;
}

.participant-icon {
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
}
.participant-icon > div {
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
}

.participant-icon img {
  border: 2px solid white;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

button:hover .hover-button-icon {
  filter: invert(75%);
}

.light-text {
  color: white;
}

.konvajs-content {
  border-left: 1px solid #3E3E3E;
  border-right: 1px solid #3E3E3E;
}
