.presentation-meeting-container {
    display: flex;
    height: 100%;
    justify-content: space-evenly;
}

.presentation-meeting-container > div:first-child {
    flex-grow: 2;
    display: flex;
    justify-content: center;
}

.presentation-meeting-container .video-container-presenter, .video-container-presenter .participant-video-avatar {
    width: 39vw;
    height: 22vw;
}

.video-container-presenter .participant-video-avatar img {
    width: 28%;
    height: 50%;
}

.presentation-meeting-roster-container {
    height: 100%;
    display: flex;
    justify-content: end;
}

.presentation-meeting-roster-container .roster-grid-stage {
    display: flex;
    justify-content: center;
}
