.topic-note {
    white-space: pre-line;
    padding: 10px;
    background: #E0E0E0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    max-width: 20vw;
    word-wrap: anywhere;
    font-size: 18px;
}

.topic-note textarea {
    font-size: 18px;
}

.topic-note a {
    cursor: pointer;
}

.topic-note .note-controls {
    margin-top: 10px;
}
