.schedule-menu-item-title {
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
}

.active-phase .schedule-menu-item-title {
    margin-bottom: 12px;
}

.schedule-menu-item-role {
    font-weight: normal;
    margin-bottom: 1ex;
}

.schedule-menu-item-time-container {
    display: flex;
    justify-content: center;
}

div.schedule-menu-item-time {
    color: #818181;
    border-style: solid;
    border-width: 1px;
    border-radius: 9px;
    background: inherit 0 0 no-repeat padding-box;
    padding: 2px 2px;
    width: 40px;
    height: 17px;
    font-weight: normal;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.schedule-menu-item-done {
    width: 56px;
}

.menu-middle .menu-variant-full.active-phase {
    background: #4B4B4B 0 0 no-repeat padding-box;
    border-radius: 4px;
    align-items: start;
    padding: 12px 0;
    margin-right: 5px;
}

.menu-middle .menu.item {
    margin-top: 0;
    margin-bottom: 0;
}

.menu-middle .inactive-phase .menu-item-content {
    height: 19px;
}

.menu-middle .menu-variant-full.active-phase .schedule-menu-item-time {
    color: black;
    border-color: #FFFFFF;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 9px;
}

.menu-middle .menu-variant-icon.active-phase .schedule-menu-item-time {
    color: white;
    border-color: #4B4B4B;
    background: #4B4B4B 0 0 no-repeat padding-box;
    border-radius: 9px;
}

.menu-middle .active-phase button {
    background-color: transparent;
    font-size: 11px;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    font-weight: normal;
    padding-right: 7px;
    padding-left: 7px;
    width: fit-content;
    max-width: 200px;
}

.menu-middle .active-phase button:hover {
    background-color: var(--spectrum-global-color-gray-900);
}
