.participant-cafe-icon * {
    box-sizing: initial;
}

.group-call-box-participants {
    margin: 16px 38px;
}

.group-call-box ._spectrum-ButtonGroup_25328 {
    margin: 0 !important;
    display: flex;
    gap: 10px;
}

.group-call-box .card-buttons {
    display: flex;
    justify-content: center;
}

.cafe {
    height: 100%;
}

.cafe .two-column > div {
    width: 50%;
    height: 100%;
}

.cafe .two-column {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: 32px;
}

.cafe .participant-cafe-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.cafe .participant-cafe-badge * {
    box-sizing: initial;
}

.cafe .participant-cafe-badge.inactive {
    opacity: 0.5;
}
