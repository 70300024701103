.meeting-controls {
    display: inline-flex;
    gap: 7px;
    justify-content: center;
}

.meeting-controls button {
    border-color: #6f6f6f;
}

.meeting-controls button:hover {
    border-color: white;
}