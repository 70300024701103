.device-settings-dialog {
    min-width: 730px;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 4px;
}

.device-settings-dialog hr {
    background-color: #404040;
}

.device-settings-dialog video {
    max-width: 396px;
    max-height: 222px;
}

.device-settings-dialog .preview-video-container {
    width: 396px;
    height: 222px;
    flex-shrink: 0;
}

.device-settings-dialog .icon-button {
    width: 42px;
    height: 32px;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
}

.device-settings-dialog ._spectrum-ToggleSwitch-input_fc100~._spectrum-ToggleSwitch-label_fc100,
.device-settings-dialog section span,
.device-settings-dialog h3 {
    color: white !important;
}

.device-settings-dialog section button:hover,
.device-settings-dialog section button,
.device-settings-dialog input {
    background-color: #404040 !important;
    border: 1px solid #6F6F6F !important;
    border-radius: 4px !important;
    color: white !important;
}

.device-settings-dialog label,
.device-settings-dialog {
    color: white;
    background-color: #404040;
}

.device-settings-dialog ._spectrum-ToggleSwitch-switch_fc100 {
    background-color: white;
}

.device-settings-dialog footer {
    margin-top: 25px;
    padding-top: 15px;
    border-top: 1px solid #6F6F6F;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.device-settings-dialog footer ._spectrum-Button--primary_7a745 {
    background-color: white;
    border-color: var(--spectrum-global-color-gray-800);
    color: black;
}

.device-settings-dialog footer ._spectrum-Button--secondary_7a745 {
    background-color: #404040;
    border-color: white;
    color: white;
}

.device-settings-dialog footer ._spectrum-Button--primary_7a745 span {
    color: black;
}


.device-settings-dialog ._spectrum-BarLoader_c74d2._is-positive_c74d2 ._spectrum-BarLoader-fill_c74d2,
.device-settings-dialog ._spectrum-BarLoader-fill_c74d2 {
    background-color: white;
}

.device-settings-dialog ._spectrum-BarLoader-track_c74d2 {
    background-color: #6F6F6F;
}

.device-settings-dialog .meter-container {
    margin-bottom: -10px;
}

.device-settings-dialog .meter-container,
.device-settings-dialog .meter > div,
.device-settings-dialog .meter {
    width: 100%;
}
