.topic-room-header-closed {
    margin-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    margin-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--spectrum-global-color-gray-200);
    width: 24px;
    height: 24px;
    background-color: var(--spectrum-global-color-gray-200);
    color: red;
    margin-top: 6px;
}

.topic-room-header-accepted {
    margin-left: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    margin-right: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--spectrum-global-color-gray-200);
    width: 24px;
    height: 24px;
    background-color: var(--spectrum-global-color-gray-200);
    color: green;
    margin-top: 6px;
}

.topic-room-header-info {
    font-weight: bolder;
    font-size: 24px;
}

.topic-description-container-row, .whiteboard-container-row {
    width: 100%;
    color: black;
}

.topic-room .row-layout > div,
.topic-room .row-layout {
    width: 100%;
}

.topic-description-container-row button {
    background-color: transparent;
    color:black;
}
